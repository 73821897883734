<template>
  <div class="gallery">
    <b-button v-b-toggle.gallery-map-side variant="creation" class="btn-side left">
      {{ $t('map') }}
    </b-button>

    <side-view id="gallery-map-side" :visible="mapIsVisible">
      <template #default="{ hide, visible }">
        <gallery-map
          v-if="creations && creation"
          :nodes="creations" :node="creation"
          :visible="visible"
          @open-creation="openCreation($event, hide)"
        />
      </template>
    </side-view>

    <gallery-view
      v-if="creation"
      :node="creation" :key="creation ? creation.id : 0"
      @open-creation="openCreation"
      @view-origin="openLibrary"
    />

    <b-button v-b-toggle.gallery-index variant="creation" class="btn-side right">
      {{ $t('index') }}
    </b-button>

    <side-view id="gallery-index" right>
      <template #default="{ hide }">
        <page-view
          v-if="intro"
          :page="intro" no-close slug="intro"
          class="small"
        />

        <ul v-if="creations">
          <li v-for="crea in creations" :key="crea.id">
            <node-view-title
              :node="crea"
              tag="h4" link no-date
              @open-node="openCreation(crea.id, hide)"
            />
          </li>
        </ul>
      </template>
    </side-view>


    <b-modal
      v-if="creation && creation.mediaItem"
      :id="'modal-creation' + creation.id"
      class="modal-creation"
      static hide-footer hide-header
    >
      <template #default="{ hide, visible }">
        <button-expand expanded @click="hide()" />
        <template v-if="visible">
          <embed-video
            v-bind="creation.mediaItem"
            full
          />
        </template>
      </template>
    </b-modal>

    <b-overlay
      :show="!creations || !creation"
      spinner-variant="creation"
      no-wrap
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { getRelation } from '@/store/utils'
import { PageView, SideView } from '@/components/layouts'
import { NodeViewTitle } from '@/components/nodes'
import { GalleryView, GalleryMap } from '@/pages/gallery'


export default {
  name: 'Gallery',

  components: {
    PageView,
    SideView,
    GalleryView,
    GalleryMap,
    NodeViewTitle
  },

  props: {
    id: { type: [Number, String], default: undefined }
  },

  data () {
    return {
      intro: null,
      mapIsVisible: false
    }
  },

  computed: {
    ...mapGetters(['creations', 'creation'])
  },

  methods: {
    openCreation (id, hideSideView) {
      this.$store.dispatch('OPEN_CREATION', { id })
      if (hideSideView) hideSideView()
    },

    openLibrary () {
      this.$store.dispatch('OPEN_NODE', getRelation(this.creation))
    }
  },

  watch: {
    id (id, prev) {
      this.$store.dispatch('DISPLAY_CREATION', parseInt(this.id))
    }
  },

  created () {
    if (this.$route.hash === '#map') {
      this.mapIsVisible = true
    }
    this.$store.dispatch('INIT_GALLERY', parseInt(this.id))
    this.$store.dispatch('QUERY_PAGE', 'gallery').then(page => {
      this.intro = page
    })
  }
}
</script>

<style lang="scss" scoped>
.gallery {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (orientation: landscape) {
    flex-direction: row;
  }

  .btn-side {
    position: absolute;
    border-radius: 0 !important;
    align-self: center;
    min-height: 35px;
    min-width: 65px;
    text-align: center;
    border: $line;
    font-weight: $font-weight-bold;
    line-height: 0;

    @include media-breakpoint-up($size-bp) {
      min-height: 50px;
      min-width: 80px;
    }

    &.left {
      border-top: 0;

      @media (orientation: portrait) {
        top: 0;
      }

      @media (orientation: landscape) {
        transform: rotate(-90deg) translate(0, -15px);
        margin-right: -30px;
      }
    }

    &.right {
      @media (orientation: portrait) {
        bottom: 0;
        border-bottom: 0;
      }

      @media (orientation: landscape) {
        border-top: 0;
        transform: rotate(90deg) translate(0, -15px);
        margin-left: -30px;
        right: 0;
      }
    }
  }
}

::v-deep #gallery-map-side {
  width: 100%;

  .btn-close {
    float: unset;
  }
}

#gallery-index {
  ul {
    padding: 0;
    list-style: none;
    font-family: $font-family-text;
  }
}

.modal-creation {
  z-index: 1100 !important;
  width: 100%;
  height: 100%;
  pointer-events: none;

  ::v-deep .modal {
    position: absolute;
    background-color: transparent;
    width: 100%;
    max-height: 100%;
    overflow: hidden;

    &-dialog {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    &-content {
      height: 100%;
      border: 0;
    }

    &-body {
      padding: 0;
      display: flex;
    }

    &-backdrop {
      display: none;
    }

    iframe {
      border: 0;
    }

    .btn-expand {
      position: absolute;
      top: 0;
      right: 0;
    }

    audio {
      width: 80%;
      margin: auto;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
