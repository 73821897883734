var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gallery"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.gallery-map-side",modifiers:{"gallery-map-side":true}}],staticClass:"btn-side left",attrs:{"variant":"creation"}},[_vm._v(" "+_vm._s(_vm.$t('map'))+" ")]),_c('side-view',{attrs:{"id":"gallery-map-side","visible":_vm.mapIsVisible},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
var visible = ref.visible;
return [(_vm.creations && _vm.creation)?_c('gallery-map',{attrs:{"nodes":_vm.creations,"node":_vm.creation,"visible":visible},on:{"open-creation":function($event){return _vm.openCreation($event, hide)}}}):_vm._e()]}}])}),(_vm.creation)?_c('gallery-view',{key:_vm.creation ? _vm.creation.id : 0,attrs:{"node":_vm.creation},on:{"open-creation":_vm.openCreation,"view-origin":_vm.openLibrary}}):_vm._e(),_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.gallery-index",modifiers:{"gallery-index":true}}],staticClass:"btn-side right",attrs:{"variant":"creation"}},[_vm._v(" "+_vm._s(_vm.$t('index'))+" ")]),_c('side-view',{attrs:{"id":"gallery-index","right":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [(_vm.intro)?_c('page-view',{staticClass:"small",attrs:{"page":_vm.intro,"no-close":"","slug":"intro"}}):_vm._e(),(_vm.creations)?_c('ul',_vm._l((_vm.creations),function(crea){return _c('li',{key:crea.id},[_c('node-view-title',{attrs:{"node":crea,"tag":"h4","link":"","no-date":""},on:{"open-node":function($event){return _vm.openCreation(crea.id, hide)}}})],1)}),0):_vm._e()]}}])}),(_vm.creation && _vm.creation.mediaItem)?_c('b-modal',{staticClass:"modal-creation",attrs:{"id":'modal-creation' + _vm.creation.id,"static":"","hide-footer":"","hide-header":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
var visible = ref.visible;
return [_c('button-expand',{attrs:{"expanded":""},on:{"click":function($event){return hide()}}}),(visible)?[_c('embed-video',_vm._b({attrs:{"full":""}},'embed-video',_vm.creation.mediaItem,false))]:_vm._e()]}}],null,false,63695740)}):_vm._e(),_c('b-overlay',{attrs:{"show":!_vm.creations || !_vm.creation,"spinner-variant":"creation","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }